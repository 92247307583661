@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800;900&display=swap");

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  background:#f3f4f6;
}

.primary{
  background: #d1ecf1;
  color: #247080;
  border: 1px solid #247080;
}

.secondary{
  background: #d2f9ee;
  color: #228f70;
  border: 1px solid #228f70;
}

.ternary{
  background: #fddcdf;
  border: none;
  color: #f65a6f;
  border: 1px solid #f65a6f;
}



/* background: #d6d8d9;
color: #3a3e41; */



.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.container {
  display: grid;
  // height: 100vh;
  grid-template-columns: 0.66fr 1fr 1fr 1fr;
  // grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
  /* grid-gap: 0.2rem; */
}

.navbar {
  background: #ffffff;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray
}

.nav_icon {
  display: none;
}

.nav_icon > i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 15px;
  font-weight: 700;
}

.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

.navbar__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right > a > i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  padding: 7px;
}

main {
  background: #f3f4f6;
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  // display: flex;
  // flex-direction: row;
  // gap: 2rem;
  padding: 20px 35px;
}

.main__title {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.main__greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.main__greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap: 30px;
  margin: 20px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // height: 7rem;
  padding: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}
.card__container{
  align-items: center;
  justify-content: space-between;
}
.card_image{
  width: 20rem;
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.card_content{
  display: flex;
  flex-direction: row;
}

.card_inner > span {
  font-size: 25px;
}

.conversion {
  background-color:#228f70 ;
  padding: 0.2rem 1rem;
  height: 2rem;
  margin-top: 1rem;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.session {
  display: grid;
  grid-template-columns: 1fr ;
  gap: 30px;
  margin-top: 50px;
}

.session__grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  gap: 30px;
  margin: 20px 0;
}

.session__container {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.session__container__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.session__container__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.session__container__title > div > p {
  font-size: 14px;
}

.session__container__title > span {
  color: #ffffff;
  font-size: 15px;
  background: #3ea175;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
//   @media (mix-width: 855px) {
//     padding: 10px;
//     font-size: 12px;
//     width: 1rem;
// }
}

.charts__right {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title > i {
  color: #ffffff;
  font-size: 20px;
  background: #39447a;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}


.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.logo{
  height: 40px;
  display: none;
  @media(max-width: 992px) {
    display: block;
  }
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

/*  SIDEBAR STARTS HERE  */

#sidebar {
  background: #020509;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  transition: .5s;
  height: 100vh;
  position: fixed;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}

.sidebar__img {
  display: flex;
  align-items: center;
  h1{
    color: #ffff;
    font-weight: bolder;
    font-size: 1rem;
  }
}
.sidebar__img > img {
 height: 40px;
}


.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  //color: #3ea175;
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  // transition: 5s;
  transition: 0.3s;
  // height: 100vh;
}

#sidebarIcon{
  cursor: pointer;
}

// delete Account

.main-content{
  padding-top: 10px;
  font-family: "Inter";
  margin: 2rem;
}
.main-heading{
  color: rgb(38 38 38);
}

.main-heading h1{
   font-weight: 400;
   font-size: 32px;
}

.main-heading div{
  font-weight: 400;
  font-size: 18px;
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.image-content img{
  max-width: 100%;
  height: auto;
}

.image-content div{
  font-size: 18px;
  font-style: italic;
  margin-top: 1.5rem;
}

.list-header{
  font-weight: 600;
  font-size: 18px;
  margin-top: 2rem;

}

.lists-content {
  margin-top: 0.5rem;
  margin-left: 1.5rem;
} 

.lists-content li:first-child{
  margin-top: 1rem;
}

.lists-content li {
  margin-top: 0.5rem;
  font-size: 18px;
  font-weight: 400;
}

.lists-content i {
  font-weight: bold;
}

.lists-content li:last-child{
  font-weight: bold;
}

// .aside {
//   border: none;
//   background-color: white;
//   width: 35%;
//   padding: 2rem 0 2rem 2rem;
//   font-family: "Inter";
//   box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
//   border-radius: 5px;
//   border: 1px solid #d3d3d3;
// }

@media only screen and (min-width: 768px) {
  .main-content{
  padding-left: 3rem;
  padding-right: 3rem;
  }
  
}

@media only screen and (min-width: 300px) {
  .main-content{
  padding-left: 2rem;
  padding-right: 2rem;
  }
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 0.2fr 2.2fr; */
    // grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
  .session__container__title > span {
    padding: 10px;
    font-size: 0.5rem;
    width: 2rem;
  }

  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }

  .nav_icon {
    display: inline;
  }
  .main__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 20px 35px;
    margin: 0.5rem;
  }

  // .aside {
  //   width: 95%;
  //   padding: 1rem 0 1rem 1rem;
  //   border-radius: 5px;
  //   box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  //   border: none;
  // }
}

@media only screen and (max-width: 855px) {
  .sidebar__title > div > img {
    width: 45px
  }
  .main__cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
  .session__container__title > span {
    padding: 10px;
    font-size: 0.5rem;
    width: 2rem;
  }

  .session {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  .session__grid{
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
  // .aside {
  //   width: 98%;
  // }
  
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
  .session__container__title > span {
    padding: 10px;
    font-size: 0.5rem;
    width: 2rem;
  }
  // .aside {
  //   width: 95%;
  // }
  
}

@media screen and (max-width:1024px) {
  .main__container{
    flex-direction: column;
  }
  // .aside{
  //   width: 96%;
  //   box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  //   border: none;
  // }
}

@media screen and (max-width:1280px) {
  .main__container{
    flex-direction: column;
  }
  // .aside{
  //   width: 97%;
  //   box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  //   border: none;
  // }
}



