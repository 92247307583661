.chatpage {
  position: relative;

  .chat_grid {
    width: 100%;
    height: calc(100vh - 61px);
    display: flex;

    .learners {
      border-right: 1px solid lightgrey;

      .learners_list {
        // height: calc(100vh - 109px);
        overflow-y: auto;
        flex: 1;
        a,
        a:visited,
        a:active,
        a:hover {
          text-decoration: none;
          color: inherit;
        }
        .item {
          overflow: hidden;
          .MuiListItem-root .MuiTouchRipple-child {
            background-color: red !important;
          }

          &.Mui-selected {
            background-color: rgba(0, 0, 0, 0.04);
          }

          .last_msg {
            max-width: 180px;
          }

          .last_msg p {
            width: 100%;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .learner_name {
            color: #000000de;
          }

          .badge {
            background-color: red;
            color: white;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 50%;
          }
        }
      }
    }

    .select_conv {
      height: calc(100vh - 61px);
      display: none;
      flex-direction: column;
      background-color: rgb(241, 245, 249);
      justify-content: center;
      align-items: center;
      .chat_icon {
        font-size: 100px;
        color: rgba(0, 0, 0, 0.26);
      }

      @media screen and (min-width: 600px) {
        display: flex;
      }  
    }
  }

  .chat_section {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .chat_header {
      border-bottom: 1px solid lightgrey;
      padding: 10px 0;
      display: flex;
      align-items: center;

      .heading,
      .text {
        font-weight: bold;
        font-size: 18px;
      }

      .heading {
        flex: 1;
      }
      .iconbtn {
        border-radius: 10px;
        color: #00cdac !important;
        display: flex;
        gap: 10px;
      }
      .iconbtn p {
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 16px;
      }
    }

    .msgText{
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
      }
    }

    .messagearea {
      display: flex;
      flex: 1;
      flex-direction: column;
      // height: calc(100vh - 181.4px);
      overflow-y: auto;
      .msg {
        display: flex;
        padding: 10px 20px;
      }
      .msg_left {
        justify-content: flex-start;
        .msg_bubble_data_box {
          background: rgb(235, 235, 235);
        }
      }
      .msg_right {
        justify-content: flex-end;
        .msg_bubble_data_box {
          background: rgb(36 167 145);
          color: rgb(253 238 238);
        }
      }
      .msg_bubble.img_msg {
        height: 300px;
      }
      .msg_bubble {
        display: flex;
        flex-direction: column;
        max-width: 270px;
        min-width: 62px;
        gap: 2px;

        &_data_box {
          padding: 6px 8px;
          border-radius: 5px;
          cursor: pointer;
        }

        &_text {
          word-wrap: break-word;
          margin: 0;
          span {
            font-size: 14px;
          }
        }

        &_img {
          max-width: 100%;
          height: 250px;
          object-fit: cover;
        }

        &_time {
          margin: 0;
          margin-left: auto;
          p {
            font-size: 12px;
          }
        }

        .btn-container {
          display: flex;
          align-items: center;
          gap: 8px;
      
          button{
            padding: 5px 4px;
            width: 65px;
            border-radius: 20px;
            border-width: 1px;
            color: #3C4A47;
            font-size: 12px;
            font-weight: 600;
            outline: none;
            box-shadow: none;
            border-style: solid;
            cursor: pointer;
            font-family: Lato;
          }
      
          .book_btn{
            border-color: #24A791;
            background: #D3EDE9;
            white-space: nowrap;
            &:hover {
              background: #A7DCD3;
            }
          }
          .reject_btn{
            border-color: #EF4444;
            background: #FEF2F2;
            white-space: nowrap;
            &:hover {
              background: #FEE2E2;
            }
          }
      
        }
      
      }
      .msg_date {
        text-align: center;
        background-color: #ffffff;
        color: #0e0e0e;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
        padding: 4px 10px;
        margin: 20px auto;
      }
    }

    .send_msg {
      position: relative;
      align-items: center;
      padding: 10px 20px;
      border-top: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      .msg_input {
        width: 100%;
        padding: 10px 15px;
        box-sizing: border-box;
        outline: none;
        border: none;
        font-size: 16px;
      }
      .request_btn{
        border-color: #1D8674;
        color: #1D8674;
        white-space: nowrap;
        width: auto;
        padding: 6.5px 16px;
        &:hover {
          border-color: inherit;
        }

        @media screen and (min-width: 600px) {
          width: 396px;
        }    
      }
    }
  }
}

.pdfdoc {
  .react-pdf__Page {
    height: 500px;
  }
  canvas {
    height: 500px !important;
  }
}

.pdf_control_box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  &_btn {
    outline: none;
    border: none;
    font-size: 48px;
    text-align: center;
    color: #000;
    border-radius: 4px;
    width: 44px;
    height: 44px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #e6e6e6;
    }
    &:disabled {
      opacity: 0.3;
      background: #fff !important;
    }
  }
}

// src/DateTimePicker.scss

.picker-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: #fef9f3;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 40px 16px 16px 16px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -2px 15px;
  box-sizing: border-box;

  @media screen and (min-width: 900px) {
    gap:20px;
    flex-direction: row;
    padding: 0 10px;
    position: unset;
    border-radius: 0;
    box-shadow: none;
    background-color: white;
  }

  .heading{
    font-size: 20px;
    color: #141918;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: 'Segoe UI';

    @media screen and (min-width: 900px) {
      display: none;
    }

  }

  .picker_msg{
    display: block;
    @media screen and (min-width: 900px) {
      display: none;
    }
  }


  .inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    // border: 1px solid #00ad966c;
    padding: 8px 0px;
    border-radius: 4px;
    font-weight: bold;

    @media screen and (max-width: 600px) {
      gap:5px;
    }
  }
  .btn-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    
    @media screen and (max-width: 900px) {
      margin-top: 20px;
    }


    button{
      padding: 12px 16px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: normal;
      text-transform: capitalize;
      box-shadow: none;
      @media screen and (max-width: 900px) {
        flex: 1;
      }
  
    }

    .submitRequest_btn{
      border-color: #24A791;
      color: white;
      background: #24A791;
      white-space: nowrap;
      width: auto;
    }
    .cancel_btn{
      border: 1px solid #AABAB7;
      background: white;
      white-space: nowrap;
      width: auto;
      color: black;
    }

  }

  .picker input {
    background-color: inherit;
    padding: 10px 24px;
    border: 1px solid #AABAB7;
    border-radius: 10px;
    box-sizing: border-box;
    outline: 0;
    position: relative;
    font-size: 17px;
    color: #141918;
  }

  .time-picker {
    background-color: inherit;
    padding: 10px 14px;
    border: 1px solid #AABAB7;
    border-radius: 10px;
    box-sizing: border-box;
    outline: 0;
    position: relative;
    font-size: 17px;
    color: #141918;
  }

  .picker-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  button {
    border: none;
    outline: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 0.2rem 0.3rem;
    text-align: center;
    color: #ffffff;
    background: #007465;
    border-radius: 8px;
    cursor: pointer;
  }
  .cancel-btn{
    background: #EF4444;
  }
}

.msg-count{
    padding: 2px;
    min-height: 14px;
    min-width: 14px;
    text-align: center;
    margin-left: 6px;
    font-weight: bold;
    color: #fff;
    background: rgb(203, 3, 3);
    border-radius: 25px;
    font-size: 12px;
}

.tag{
  padding: 6px;
  min-height: 14px;
  min-width: 14px;
  text-align: center;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: rgb(203, 3, 3);
  border-radius: 25px;
  font-size: 12px;
}