@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./chatpage";

.referral-navbar {
  margin: 1rem 8%;
}

.grid-container {
  margin: 1rem 6%;
}
.referral-title {
  margin: 1rem 8%;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.005em;
  color: #0e433a;
  @media (max-width: 992px) {
    font-size: 25px;
  }
}

.heading {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}
.not-available {
  text-align: center;
  margin: auto;
  padding: 2rem 0;
}
.feedback_textarea {
  width: 30rem;
  height: 5rem;
  font-size: 1.5rem;
  padding: 0.7rem;
  border: 1px solid #00ad96;
  &:focus {
    outline: none !important;
    border-color: #719ece;
    box-shadow: 0 0 5px #719ece;
  }
  @media (max-width: 992px) {
    width: 12rem;
  }
}
.query_input {
  border: 1px solid #3f51b5;
  // border-radius: 25px;
  padding: 0.6rem;
  font-size: 1.4rem;
  width: 60rem;
  outline: none;
  @media (max-width: 992px) {
    width: 94%;
  }
}
.otp_input {
  width: 3rem !important;
  height: 3rem;
  font-size: 1.8rem;
  margin: 0.4rem;
}
#session {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 120%;
  .session_time {
    background-color: #00ad96;
    text-align: center;
  }
  td {
    span {
      display: flex;
      flex-direction: column;
      .completed {
        background-color: rgba(3, 87, 3, 0.541);
      }
      .reserved {
        background-color: #c57a0080;
      }
      a {
        margin: 0.1rem;
        padding: 0.4rem;
        text-decoration: none;
        color: #ffffff;
        font-size: 0.8rem;
        border-radius: 25px;
      }
    }
  }
  .availableTime {
    background: rgba(255, 6, 243, 0.158);
  }
}

#session td,
#session th {
  border: 1px solid rgb(173, 173, 173);
  padding: 8px;
  text-align: center;
}

// #session tr:nth-child(even){background-color: #f2f2f2;}

#session tr:hover {
  background-color: #ddd;
}

#session th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #228f70;
  color: white;
  text-align: center;
}

.main {
  // width: 100%;
  .react-pdf__Page__canvas {
    width: 100% !important;
    // background-size:cover;
    object-fit: contain;
  }
}

.pastsession {
  .MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    font-size: 0.8rem;
    margin: 0;
    font-weight: bolder;
  }
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.danger-row {
  background: #ff7f7f5b;
  text-align: center;
}

.success-row {
  background: #7fff7f5b;
  text-align: center;
}

.first-row {
  background-color: rgb(7, 172, 7);
}

.warning-row {
  background: #fff3cd;
  text-align: center;
}

.disputeBtn.outlined,
.disputeBtn.contained {
  width: 95px;
}

.disputeBtn.outlined {
  background: #fff;
  color: #000;
}

.disputeBtn.contained {
  background: #00cdac;
  color: #fff;
}

.disputeBtn.contained:hover {
  background: #00cdac;
}

.disputeBtn.outlined:hover {
  background: #fff;
  color: #000;
}

.date-picker {
  display: flex;
}

.date_conversion {
  display: grid;
  grid-template-columns: 1.2fr 1.7fr;
  gap: 0px;
}

@media only screen and (max-width: 992px) {
  .date_conversion {
    grid-template-columns: 1fr;
  }
}

.date-picker input {
  border: 1px solid #00ad966c;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 25%;
  border-radius: 8px;
  margin: 1rem;
}

.date-picker p {
  align-self: center;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.search-btn {
  padding: 0 1rem;
  border: none;
  border-radius: 8px;
  background: #00ad96a9;
  color: #ffffff;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
  cursor: pointer;
}

.profile-pic {
  position: relative;
}

.birthday-wish {
  padding: 2rem;
  background-color: #00953e;
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  color: white;
  width: 92%;
  border-radius: 2rem;
  text-align: center;
  font-family: "Inter";
}

.birthday-cap {
  display: none;
}

@media (min-width: 992px) {
  .birthday-cap {
    position: absolute;
    top: 21%;
    left: 30%;
    transform: rotate(20deg);
    border-radius: 50%;
    z-index: 1;
    display: block;
  }
}

@media (max-width: 992px) {
  .birthday-wish {
    width: 80%;
    margin-left: 0;
  }
}

.referral-profile {
  padding: 1rem;
  margin: 2% 0.5rem 0 0.5rem;
  border-radius: 18px;

  @media (max-width: 992px) {
    border-radius: 0;
    box-shadow: none;
  }

  .hr {
    margin: 0.6rem 0;
  }
  .profile {
    display: flex;
    .profileImage {
      width: 8rem;
      height: 8rem;
      filter: drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.16));
      border-radius: 9px !important;
    }
    @media (max-width: 992px) {
      .profileImage {
        width: 6rem;
        height: 6rem;
      }
    }
  }
  .teacher-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    // line-height: 24px;
    text-align: center;
    color: #0e433a;
    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  .counts {
    margin: 0 0 0 1rem;
    width: 15rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: #0e433a;
    }
    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #647b76;
    }
    .star {
      color: #ccb266;
    }
    @media (max-width: 992px) {
      width: 100%;
      width: 13rem;
    }
  }

  .center {
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #0e433a;
  }
  .about {
    width: 30rem;
    margin: 1rem 0;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.25px;
      color: #0e433a;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .refer {
    width: 30rem;
    .referral-code {
      margin: 1rem 0;
      width: 70%;
      padding: 0.5rem;
      border: 2px dashed #809ae9;
      box-sizing: border-box;
      border-radius: 9px;
      background: transparent;
      background-clip: padding-box;
      input {
        font-size: 16px;
        line-height: 24px;
        border: none;
        background: transparent;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.0025em;
        color: #0e433a;
        text-transform: uppercase;
      }
      button {
        border: none;
        outline: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 0.7rem;
        text-align: center;
        color: #ffffff;
        background: #4a6ee0;
        border-radius: 24px;
        cursor: pointer;
      }
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.25px;
      color: #0e433a;
    }
    .install-app {
      margin: 1rem 0;
      .download-btn {
        margin: 0.5rem 0;
        :nth-child(2) {
          margin-left: 1rem;
        }
      }
    }
    @media (max-width: 992px) {
      width: 100%;
      input {
        width: 80%;
      }
      .referral-code {
        width: 100%;
      }
    }
  }
}

.session-card {
  box-shadow: 0 8px 40px -12px rgba(0, 0, 0, 0.3);
  // width: 90%;
  padding: 1.5rem;
  position: relative;
  margin: auto;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
    small {
      font-size: 10px;
      background: #d13800;
      color: #ffffff;
      padding: 0.2rem;
      border-radius: 8px;
    }
  }
  .info {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    .info-inner {
      :nth-child(1) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: #616161;
      }
      :nth-child(2) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
      }
    }
  }
  .media {
    margin-top: 0.6rem;
    display: flex;
    justify-content: space-between;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #4dc591;
      cursor: pointer;
    }
  }
  .requirement {
    margin-top: 0.6rem;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #616161;
    }
    .requirement-inner {
      margin-top: 0.4rem;
      display: flex;
      flex-wrap: wrap;
      .pills {
        background: #3e477a;
        padding: 0.2rem;
        border-radius: 8px;
        color: #ffffff;
        margin: 0 0.2rem 0.2rem 0;
        font-size: 12px;
      }
    }
  }
  .btn-container {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      border: none;
      border-radius: 10px;
      outline: none;
      outline-offset: 0;
      padding: 0.8rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 154.3%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      cursor: pointer;
      position: relative;
      display: inline-block;
    }
    .hot-lead {
      background: #6e85ff;
    }
    .join {
      background: #4dc591;
    }
    .block {
      background: #ff6e6e;
    }
    .cancel {
      // width: 100%;
      background: #ff6e6e;
    }
    .feedback {
      background: #6e85ff;
    }
    .score {
      background: #4dc591;
      cursor: pointer;
    }
    .link {
      background: #63828e;
    }
  }
}
.session-card:hover {
  box-shadow: 0 16px 70px -12.125px rgba(0, 0, 0, 0.3);
}

.farewell {
  background: #d97706;
  padding: 0.3rem;
  border-radius: 4px 4px 0 0;
  text-align: center;
  color: white;
  font-weight: 800;
}

button:disabled,
button[disabled] {
  cursor: not-allowed !important;
  background: #797979a8 !important;
}

.referral-details {
  margin-left: auto;
  margin-right: auto;
  background: conic-gradient(
    from 25.43deg at 50% 0%,
    #edf1fc 0deg,
    #ecfdf5 360deg
  );
  border: 1px solid #d3ede9;
  box-shadow: 0px 8px 42px rgba(100, 123, 118, 0.2);
  height: 418px;
  width: 80% !important;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: -0.0025em !important;
    color: #0e433a !important;
    width: 80% !important;
  }
  .follow-us {
    margin-top: 30%;
    .share-btn {
      display: flex;
      justify-content: space-between;
      width: 40%;
      margin-top: 0.5rem;
    }
  }
  @media (max-width: 992px) {
    width: 100% !important;
    p {
      width: 100% !important;
    }
    .share-btn {
      width: 80% !important;
    }
  }
}

.main__container {
  @media (max-width: 992px) {
    padding: 0.5rem;
  }
}
.session__container {
  @media (max-width: 992px) {
    padding: 0.8rem;
  }
}

/* === BASE HEADING === */

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 30px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}

.note {
  text-align: center;
}

.custom-link {
  color: inherit;
}

/* === HEADING STYLE #1 === */
.one h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 10px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}

.fa-star {
  color: #ffa600;
}

.wrap {
  position: relative;
  .cancelled {
    cursor: not-allowed;
    position: relative;
    filter: blur(1.5px) brightness(70%);
    background: #00000083;
    z-index: 1;
    button {
      cursor: not-allowed;
    }
  }
  .override {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 154%;
    color: #ffffff !important;
  }
  .not-canceled {
    display: none;
  }
}

.earning-calculation-container {
  display: flex;
  .total-calculation {
    font-family: sans-serif;
    padding: 1rem !important;
    border-radius: 10px;
    p,
    h5 {
      line-height: 1.5rem !important;
    }
    .red {
      color: #b80000;
    }
    .total-earning {
      color: #00953e;
    }
  }
  justify-content: space-around;
  @media (max-width: 992px) {
    display: block;
  }
}

.earning-card {
  display: flex !important;
  flex-direction: column;
}

.payout-card {
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .payment {
    h5 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #414141;
    }
    span {
      padding: 0.3rem;
      color: #ffffff;
      border-radius: 8px;
      font-size: 0.8rem;
      font-weight: bold;
      text-align: center;
    }
    .Withdrawn {
      background: #01af66;
    }
    .Approved {
      background: #009db9;
    }
    .Requested {
      background: #b96300;
    }
  }
  .dates {
    padding-top: 1rem;
    span {
      p:nth-child(1) {
        font-size: 1rem;
        font-weight: bold;
        color: #383838;
      }
      p:nth-child(2) {
        font-size: 0.9rem;
        color: #616161;
      }
    }
    button {
      padding: 0.5rem;
      border-radius: 10px;
      cursor: pointer;
      background: #09bb68;
      color: #ffffff;
      font-weight: bold;
      font-size: 0.8rem;
      outline: none;
      border: none;
      width: 30%;
    }
    @media (max-width: 992px) {
      display: block;
      span {
        display: flex;
        justify-content: space-between;
      }
      button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
}
.payout-card:hover {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.feedback-heading {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}

.feedback-subheading {
  color: #373737;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.feedback-container {
  padding: 64px 26px 24px 26px;
  width: 898px;
}
.feedback-points-container {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.feedback-attributes-container {
  margin-top: 16px;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.feedback-points {
  cursor: pointer;
  border-radius: 8px;
  background: #dbdbdb;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}
.other-feedback-heading {
  color: #151515;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 18px;
}
.other-feedback-subheading {
  color: #151515;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 8px;
}
.feedback-points-details-container {
  margin: 20px;
}
.feedback-textarea {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
#outlined-multiline-static {
  flex-grow: 1;
  border-radius: 8px;
  background: #dfdfdf;
  outline: none;
  padding: 16px;
  border: none;
  font-family: Inter;
}
.feedback-middle-empty-container {
  height: 150px;
}
.feedback-note {
  color: #727272;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 16px;
}
.feedback-submit-btn {
  cursor: pointer;
  border-radius: 8px;
  background: #52c5b6;
  display: flex;
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  border: none;
  outline: none;
}
.topic-heading {
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
}
.feedback-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 8px 0;
}
.feedback-option {
  width: 170px;
  height: 100px;
  border-radius: 8px;
  background: #cee9e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}
.feedback-option-title {
  color: #151515;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}
.feedback-choice {
  display: flex;
  gap: 16px;
}
.feedback-choice-positive {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.issue-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.issue-title {
  color: #151515;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.issue-subcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.issue {
  color: #151515;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #4ebf9f;
  cursor: pointer;
}
.feedback-content {
  color: #000000;
  .select-feedback {
    display: flex;
    flex-wrap: wrap;
    span {
      border: 2px solid transparent;
      background: #e9f6f4;
      border-radius: 6px;
      padding: 0.5rem;
      margin: 0.4rem;
      font-family: Poppins;
      font-style: normal;
      color: rgba(24, 25, 31, 0.664);
      cursor: pointer;
      p {
        font-weight: 450;
        font-size: 14px;
        line-height: 15px;
      }
      small {
        font-size: 10px;
        line-height: 5px;
      }
    }
    .active {
      border: 2px solid #00cdac;
    }
  }
  .disabled-feedback {
    display: flex;
    flex-wrap: wrap;
    span {
      cursor: not-allowed !important;
      border: 2px solid transparent;
      background: #e9f6f4;
      border-radius: 6px;
      padding: 0.5rem;
      margin: 0.4rem;
      font-family: Poppins;
      font-style: normal;
      color: rgba(24, 25, 31, 0.664);
      cursor: pointer;
      p {
        font-weight: 450;
        font-size: 14px;
        line-height: 15px;
      }
      small {
        font-size: 10px;
        line-height: 5px;
      }
    }
    .active {
      border: 2px solid #00cdac;
    }
  }

  .textarea {
    outline: none !important;
    padding: 16px;
    width: 80%;
    background-color: #f3fffe;
  }
  .customfeedback {
    color: #01af66;
    cursor: pointer;
  }
}
.feedback-subAttribute-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 20px;
  border-radius: 5px;
  border: 0.2px solid #e3e3e3;
  background: #f9f9f9;
}
.feedback-subAttribute-title {
  color: #0e433a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.abs {
  position: relative;
  .bank-with-prompt {
    font-weight: bold;
    z-index: 10 !important;
    position: absolute;
    background-color: white;
    box-shadow: 3px 4px 6px 10px #3c3a3aa1;
    border-radius: 6px;
    text-align: center;
    padding: 50px;
    width: 40%;
    margin: 20% 30%;
    .buttons {
      margin-top: 30px;
      display: flex !important;
      justify-content: space-between !important;
    }
    button {
      cursor: pointer;
      color: white;
      font-weight: bold;
      padding: 10px;
      border-radius: 8px;
      outline: none !important;
      border: none !important;
      box-shadow: 3px 4px 5px #dadada;
    }
    .verify {
      background-color: #00953e;
    }
    .verify:hover {
      background-color: forestgreen;
    }
    .not-verified {
      background-color: #b80000;
    }
    .not-verified {
      background-color: maroon;
    }
  }
}

.slotContainer {
  display: flex;
}

.faq-container {
  margin: 2rem;
  font-family: "Inter";
}

.faq {
  margin: 0 2rem 0 2rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: bold;
}

.faq-heading {
  color: #24a791;
}

.faq-questions-container {
  margin: 2rem;
  border-radius: 1rem;
  background-color: #e5e5e5;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 2rem 2rem;
  cursor: pointer;
}

.faq-bullets {
  color: linear-gradient(0deg, #52a49a -1.21%, #00cdac);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  text-align: center;
  align-items: center;
}

// .faq-content {
//   margin: 4px;
// }

.faq-text {
  margin: 4px;
  font-weight: 400;
  background-color: #e5e5e5;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 3.5rem;
}

// .list{
//   list-style-type: disc;
//   margin-top: 2rem;
// }

.faq-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-top: 2rem;
}

.collapsible-container {
  transition: height 0.3s ease-out;
  overflow: hidden;
}

.collapsible-content {
  height: auto;
  transition: height 0.3s ease-out;
}

.collapsible-content--closed {
  height: 0;
}

.previous-session {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: #24a791;
  font-family: "Inter";
  margin-top: 1.5rem;
  gap: 1rem;
  cursor: pointer;
}

.resource-button {
  background-color: #24a791;
  color: white;
  padding: 1rem 5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  font-family: "Inter";
  border-radius: 5px;
  margin-bottom: 2rem;
  cursor: pointer;
  border: none;
  box-shadow: 2px 2px 5px #d9d9d9, 2px 2px 5px #24a791;
}

.resource-btn {
  text-align: center;
}

.resource-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: bold;
  min-height: 100px;
  overflow: hidden;
}

.content-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resource-name {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Inter";
  line-height: 23px;
}

.resource-description {
  font-size: 0.7rem;
  font-weight: lighter;
  font-family: "Inter";
}

.view-button {
  background-color: #24a791;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  font-family: "Inter";
  border-radius: 5%;
  margin-bottom: 2rem;
  cursor: pointer;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-up {
  transform: translateY(100%);
  animation-name: slide-up;
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.toastContainer {
  inset: 0px !important;
}

.toastContainer > * {
  // position: unset!important;
  transform: none !important;
  z-index: unset !important;
}

.bookingRequestCard {
  // position: absolute;
  width: 320px;
  height: 244px;
  border-radius: 20px;
  border: 1px solid rgba(230, 234, 233, 1);
  font-family: "Inter";
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    background: rgba(255, 239, 192, 1);
    padding: 6px 0px;
    font-size: 11px;
    font-weight: 600;
    color: rgba(153, 134, 77, 1);
    text-align: center;
  }

  .body {
    flex: 1;
    background-color: #fffcf2;
    padding: 16px 16px 20px;

    .sessionInfo {
      display: flex;
      justify-content: space-between;
      .sessionType {
        color: #082b28;
        font-weight: 600;
      }
      .subject {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 81px;
        height: 16px;
        padding: 4px 8px;
        border-radius: 24px;
        background: linear-gradient(
          180deg,
          #c6dab2 -6.45%,
          rgba(215, 229, 201, 0.85) 108.98%
        );
        color: #0e433a;
        font-size: 11px;
        font-weight: 500;
      }
    }

    .learnerInfo {
      margin-top: 16px;
      .learnerName {
        color: #0e433a;
        font-size: 13px;
        font-weight: 600;
      }
      .aboutLearner {
        display: flex;
        gap: 4px;
        align-items: center;
        color: #4a6ee0;
        font-size: 11px;
        font-weight: 400;
        margin-top: 2px;
      }
    }
    .sessionTiming {
      padding-bottom: 21.6px;
      .duration {
        color: #647b76;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-top: 16px;
      }
      .timeslot {
        color: #0e433a;
        font-size: 12px;
        font-weight: 600;
        margin-top: 6px;
      }
    }

    .btnGroup {
      display: flex;
      gap: 8px;
      margin-top: 16px;

      button {
        border: none;
        cursor: pointer;
        border-radius: 10px;
        color: white;
      }

      .acceptBtn {
        width: 189px;
        height: 32px;
        background-color: #10b981;
        font-size: 13px;
        font-weight: 600;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .declineBtn {
        width: 91px;
        height: 32px;
        background-color: #fecaca;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        .xmark {
          height: 20px;
          width: 20px;
          background-color: #ef4444;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.mytoast {
  will-change: transform !important;
  padding: 0 !important;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.16) !important;
  & > div {
    margin: 0;
  }
}
@keyframes custom-enter {
  0% {
    transform: translateX(500px) translateY(245px) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(0px) translateY(0px) scale(1);
    opacity: 1;
  }
}

@keyframes custom-exit {
  0% {
    transform: translateX(0) translateY(0px) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(500px) translateY(245px) scale(0.5);
    opacity: 0;
  }
}

.live-ping-container {
  position: relative;
  height: 12px;
  width: 12px;
  margin-right: 10px;
}

.live-ping {
  color: #3ea175;
  font-size: 13px !important;
  position: absolute;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.wiggle {
  animation: wiggle 0.3s cubic-bezier(0, 0, 0.2, 1) forwards;
  animation-iteration-count: 2;
}
@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
    box-shadow: #10b981 0px 7px 29px 0px;
  }
  75% {
    transform: rotate(10deg);
    box-shadow: #10b981 0px 7px 10px 0px;
  }
}

// .custom-exit {
//   transform: scale(1);
//   opacity: 1;
//   animation-duration: 1s;
//   animation-fill-mode: forwards;
//   animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);;
//   animation-name: swipe-right;
// }
